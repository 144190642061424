
import { mapState } from "vuex"

export default {
  name: "TopCategories",
  props: {
    block: {
      type: Array,
      required: true,
    },
  },
  // async fetch() {
  //   await this.$store.dispatch('content/get_categories')
  // },
  computed: {
    ...mapState({
      categories: (state) => state.content.categories,
    }),
  },
  // activated() {
  //   if (this.$fetchState.timestamp <= Date.now() - 30000) {
  //     this.$fetch()
  //   }
  // },
  methods: {
    categoryPathMaker(cat) {
      if (cat.alias && cat.productsCount === 0 && cat.childrens_count !== 0) {
        return "/cat/" + cat.alias
      } else if (
        cat?.alias &&
        cat?.uses_options === 1 &&
        cat?.children.length === 0
      ) {
        return "/cat/" + cat.alias + "/options"
      } else {
        return "/cat/" + cat.alias + "/products"
      }
    },
  },
}
