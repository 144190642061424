
export default {
  name: "Banners",
  props: {
    block: {
      type: Array,
      required: true,
    },
  },
}
