
export default {
  name: 'SbStyled',
  props: {
    attrs: {
      type: Object,
      required: true,
    },
  },
  computed: {
    elementClass() {
      if (this.attrs && this.attrs?.class) {
        return this.attrs.class
      }
    },
  },
}
