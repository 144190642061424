import { version } from "~/package.json"

export default function ({ store, app }) {
  const waitForStorageToBeReady = async (to, from, next) => {
    await store.restored
    // console.log("It's happening")
    // console.log(store.state.content.client_version)
    // console.log('!!! ' + store.restored)
    // console.log('!!! ' + version)
    updateVersion(store)
    next()
  }
  app.router.beforeEach(waitForStorageToBeReady)
}

async function updateVersion(store) {
  if (store.getters["content/getClientVersion"] !== version) {
    // console.log('updateVersion start')
    // console.log(store.getters['content/getClientVersion'])
    // console.log('this.version:' + version)

    const workbox = await window.$workbox

    if (!workbox) {
      // console.log("Workbox couldn't be loaded.")
      return
    } else {
      workbox.addEventListener("installed", (event) => {
        if (!event.isUpdate) {
          // console.log('The PWA is on the latest version.')
          return
        }

        // console.log('There is an update for the PWA, reloading...')

        if ("serviceWorker" in navigator) {
          // Workbox cleanup
          navigator.serviceWorker
            .getRegistrations()
            .then(function (registrations) {
              for (const registration of registrations) {
                // unregister service worker
                // console.log('serviceWorker unregistered')
                registration.unregister()
              }
              // Clean Workbox caches
              if ("caches" in window) {
                caches.keys().then(function (keyList) {
                  return Promise.all(
                    keyList.map(function (key) {
                      return caches.delete(key)
                    })
                  )
                })
              }
              // Cookie cleanup
              const cookies = document.cookie.split(";")
              for (let i = 0; i < cookies.length; i++) {
                const cookie = cookies[i]
                const eqPos = cookie.indexOf("=")
                const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie
                document.cookie =
                  name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT"
              }
              // LocalStorage CleanUp and Session Storage CleanUp
              window.localStorage.clear()
              window.sessionStorage.clear()
              // Reload FUCKING PAGE
              setTimeout(function () {
                // console.log('trying redirect do')
                window.location.replace(window.location.href) // because without redirecting, first time on page load: still service worker will be available
              }, 3000)
            })
        }
      })
    }
    store.commit("content/SET_CLIENT_VERSION", version)
    // console.log(version)
    // console.log('set version')
    // console.log(store.getters['content/getClientVersion'])
  }
}
