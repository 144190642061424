export const state = () => ({
  perPage: 12,
  showFilter: {
    mobileFilter: false,
    desktopFilter: true,
  },
  sortBy: 'latest',
  filters: {},
})
export const mutations = {
  setPerPage(state, payload) {
    state.perPage = payload
  },
  setDesktopFilter(state, payload) {
    state.showFilter.desktopFilter = payload
  },
  setMobileFilter(state, payload) {
    state.showFilter.mobileFilter = payload
  },
  setSortBy(state, payload) {
    state.sortBy = payload
  },
  setFiltersQueryOnPageLoad(state, payload) {
    state.filters[payload.category] = payload.filters
  },
  addFilter(state, payload) {
    const category = payload.category
    const type = payload.type
    const id = payload.id
    state.filters = Object.assign(state.filters, {
      [category]: {
        [type]: id,
      },
    })
  },
  addSameTypeAndSameCategory(state, payload) {
    const category = payload.category
    const type = payload.type
    const id = payload.id
    state.filters[category][type] += ',' + id
  },
  addFilterTypeForTheSameCategory(state, payload) {
    const category = payload.category
    const type = payload.type
    const id = payload.id
    state.filters[category] = Object.assign(state.filters[category], {
      [type]: id,
    })
  },
  deleteSameValueFromFilters(state, payload) {
    const category = payload.category
    const type = payload.type
    const id = payload.id
    const array = state.filters[category][type].split(',')
    array.splice(array.indexOf(id), 1)
    if (array.length > 0) {
      state.filters[category][type] = array.join(',')
    } else {
      delete state.filters[category][type]
      if (state.filters[category].length <= 0) {
        this.dropCategoryFilters(category)
      }
    }
  },
  dropCategoryFilters(state, payload) {
    delete state.filters[payload]
    this.$router.push({ query: {} })
  },
}
export const actions = {
  addFilterLogic({ commit, state }, payload) {
    if (!(payload.category in state.filters)) {
      commit('addFilter', payload)
    } else if (
      payload.category in state.filters &&
      payload.type in state.filters[payload.category] &&
      state.filters[payload.category][payload.type]
        .toString()
        .match(new RegExp('(?:^|,)' + payload.id + '(?:,|$)'))
    ) {
      commit('deleteSameValueFromFilters', payload)
    } else if (
      payload.category in state.filters &&
      payload.type in state.filters[payload.category] &&
      payload.id !== state.filters[payload.category][payload.type]
    ) {
      commit('addSameTypeAndSameCategory', payload)
    } else if (
      payload.category in state.filters &&
      !(payload.type in state.filters[payload.category])
    ) {
      commit('addFilterTypeForTheSameCategory', payload)
    } else {
      // console.log('found same category,type and id. Need to Delete')
    }
    this.$router.push({
      params: { page: 1 },
      query: state.filters[payload.category],
    })
  },
}
export const getters = {
  getCategoryFilters: (state) => (category) => {
    return state.filters[category]
  },
  getTypeFiltersArray: (state) => (category, type) => {
    if (state.filters[category][type]) {
      return state.filters[category][type].split(',')
    } else {
      return []
    }
  },
}
