import Vue from 'vue'
import VueNotifications from 'vue-notifications'
import iziToast from 'izitoast' // https://github.com/dolce/iziToast
iziToast.settings({
  progressBar: true,
  close: true,
  position: 'topCenter',
  transitionIn: 'bounceInDown',
  transitionOut: 'flipOutX',
  transitionInMobile: 'bounceInDown',
  transitionOutMobile: 'flipOutX',
  animateInside: true,
})
function toast({
  theme = 'dark',
  title,
  titleColor,
  message,
  messageColor = 'white',
  progressBarColor = 'white',
  type,
  timeout = 2000,
  iconUrl,
  iconColor,
  position = 'topCenter',
  buttons = [],
  displayMode,
}) {
  if (type === VueNotifications.types.warn) {
    type = 'warning'
  }
  return iziToast[type]({
    theme,
    title,
    titleColor,
    message,
    messageColor,
    progressBarColor,
    timeout,
    iconUrl,
    iconColor,
    position,
    buttons,
    displayMode,
  })
}

const options = {
  success: toast,
  error: toast,
  info: toast,
  warn: toast,
  question: toast,
}

Vue.use(VueNotifications, options)
