export default {
  compareProducts({ commit, state }, payload) {
    const originCategory = payload?.category?.alias // Категория на англ

    const categoryIndex = state.compareProducts?.findIndex(
      (el) => el?.alias === originCategory
    ) // Записываем индекс категории

    if (categoryIndex >= 0) {
      // Получаем товар по alias
      const searchItem = state.compareProducts?.[categoryIndex]?.items?.find(
        (el) => el?.alias === payload?.options?.alias
      )

      if (!searchItem) {
        commit('ADD_PRODUCT', payload)
      } else {
        // Получаем массив товаров
        const maxItems = state.compareProducts[categoryIndex].items

        // Ищем совпадение удаляем его и возвращаем новый массив с элементами иначе просто удаляем все и возвращаем новый массив
        if (searchItem && maxItems.length < 2) {
          // Удаляем такую категорию по индексу
          commit('REMOVING_CATEGORY', payload)
        } else {
          // Удаляем такой товар по индексу
          commit('REMOVE_PRODUCT', payload)
        }
      }
    } else {
      // Добавляем новую категорию с товаром
      commit('ADD_NEW_CATEGORY', payload)
    }
  },
  toggleDialogCompare({ commit }) {
    commit('TOGGLE_DIALOG_COMPARE')
  },
}
