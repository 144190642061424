import Vue from 'vue'
import {Block, Mark} from '@marvr/storyblok-rich-text-types'
import VueRichTextRenderer from '@marvr/storyblok-rich-text-vue-renderer'

import DocumentResolver from '~/components/Resolvers/Blocks/v-document.vue'
import HeadingResolver from '~/components/Resolvers/Blocks/v-heading.vue'
import ParagraphResolver from '~/components/Resolvers/Blocks/v-paragraph.vue'
import QuoteResolver from '~/components/Resolvers/Blocks/v-quote.vue'
import OrderListResolver from '~/components/Resolvers/Blocks/v-ol-list.vue'
import UnorderedListResolver from '~/components/Resolvers/Blocks/v-ul-list.vue'
import ListItemResolver from '~/components/Resolvers/Blocks/v-list-item.vue'
import CodeBlocksResolver from '~/components/Resolvers/Blocks/v-code.vue'
import HorizontalRuleResolver from '~/components/Resolvers/Blocks/v-hr.vue'
import HardBreaksResolver from '~/components/Resolvers/Blocks/v-br.vue'
import ImageResolver from '~/components/Resolvers/Blocks/v-image.vue'

import BoldResolver from '~/components/Resolvers/Marks/v-bold.vue'
import StrongResolver from '~/components/Resolvers/Marks/v-strong.vue'
import StrikeResolver from '~/components/Resolvers/Marks/v-strike.vue'
import UnderlineResolver from '~/components/Resolvers/Marks/v-underline.vue'
import ItalicResolver from '~/components/Resolvers/Marks/v-italic.vue'
import CodeMarksResolver from '~/components/Resolvers/Marks/v-code-marks.vue'
import LinkResolver from '~/components/Resolvers/Marks/v-link.vue'
import StyledResolver from '~/components/Resolvers/Marks/v-styled.vue'

const options = {
  resolvers: {
    blocks: {
      [Block.DOCUMENT]: DocumentResolver,
      [Block.HEADING]: HeadingResolver,
      [Block.PARAGRAPH]: ParagraphResolver,
      [Block.QUOTE]: QuoteResolver,
      [Block.OL_LIST]: OrderListResolver,
      [Block.UL_LIST]: UnorderedListResolver,
      [Block.LIST_ITEM]: ListItemResolver,
      [Block.CODE]: CodeBlocksResolver,
      [Block.HR]: HorizontalRuleResolver,
      [Block.BR]: HardBreaksResolver,
      [Block.IMAGE]: ImageResolver,
    },
    marks: {
      [Mark.BOLD]: BoldResolver,
      [Mark.STRONG]: StrongResolver,
      [Mark.STRIKE]: StrikeResolver,
      [Mark.UNDERLINE]: UnderlineResolver,
      [Mark.ITALIC]: ItalicResolver,
      [Mark.CODE]: CodeMarksResolver,
      [Mark.LINK]: LinkResolver,
      [Mark.STYLED]: StyledResolver,
    },
  },
}

Vue.use(VueRichTextRenderer, options)
