import Vue from 'vue'
import Teaser from '~/components/contentBlocks/Teaser.vue'
import Grid from '~/components/contentBlocks/Grid.vue'
import Feature from '~/components/contentBlocks/Feature.vue'
import Banners from '~/components/DashboardParts/Banners'
import MobileBanners from '~/components/DashboardParts/Banners'
import TopCategories from '~/components/DashboardParts/TopCategories'

Vue.component('Teaser', Teaser)
Vue.component('Grid', Grid)
Vue.component('Feature', Feature)
Vue.component('Banners', Banners)
Vue.component('MobileBanners', MobileBanners)
Vue.component('TopCategories', TopCategories)
