export default {
  ADD_NEW_FAVORITES(state, product) {
    state.favoriteProducts.push(product?.options)
  },
  REMOVE_FAVORITES(state, product) {
    const searchItem = state.favoriteProducts?.findIndex(
      (el) => el?.alias === product?.options?.alias
    )

    // Удаляем товар на текущей позиции
    state.favoriteProducts.splice(searchItem, 1)
  },
}
