export default {
  STATUS_CREATE_PRE_ORDER(state, payload) {
    if (payload === 200) {
      state.success_pre_order = true
      state.error_pre_order = false
    } else {
      state.error_pre_order = true
      state.success_pre_order = false
    }
  },
  STATUS_INVITATION_DEALER(state, payload) {
    if (payload === 200) {
      state.success_invitation_dealer = true
      state.error_invitation_dealer = false
    } else {
      state.error_invitation_dealer = true
      state.success_invitation_dealer = false
    }
  },
  STATUS_ASK_FOR_DISCOUNT(state, payload) {
    if (payload === 200) {
      state.success_ask_for_discountr = true
      state.error_ask_for_discount = false
    } else {
      state.error_ask_for_discount = true
      state.success_ask_for_discountr = false
    }
  },
}
