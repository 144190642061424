import shareMutations from 'vuex-shared-mutations'

export default ({ store }) => {
  window.onNuxtReady((nuxt) => {
    shareMutations({
      predicate: (mutation, state) => {
        // Conditionally trigger other plugins subscription event here to
        // have them called only once (in the tab where the commit happened)
        // ie. save certain values to localStorage
        // pluginStateChanged(mutation, state)
        return true
      },
    })(store)
  })
}
