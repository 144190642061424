export default {
  favoriteProducts({ commit, state }, payload) {
    // Ищем такой же товар
    const searchItem = state.favoriteProducts?.find(
      (el) => el?.alias === payload?.options?.alias
    )

    if (!searchItem) commit('ADD_NEW_FAVORITES', payload)
    else commit('REMOVE_FAVORITES', payload)
  },
}
