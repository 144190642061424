const deepEqual = require('deep-equal')
export default function ({ store, redirect, query, params, route }) {
  if (typeof params.page === 'undefined') {
    redirect({ params: { page: 1 } })
  }
  const equal = deepEqual(query, store.state.products.filters[params.category])
  if (!equal && !query && store.state.products.filters[params.category]) {
    history.pushState(
      {},
      null,
      route.path +
        '?' +
        Object.keys(store.state.products.filters[params.category])
          .map((key) => {
            return (
              encodeURIComponent(key) +
              '=' +
              encodeURIComponent(
                store.state.products.filters[params.category][key]
              )
            )
          })
          .join('&')
    )
  } else {
    store.commit('products/setFiltersQueryOnPageLoad', {
      category: params.category,
      filters: query,
    })
  }
  // if (
  //   JSON.stringify(query) !==
  //   JSON.stringify(store.state.products.filters[params.category])
  // ) {
  //   if (
  //     query &&
  // Object.keys(query).length === 0 && query.constructor === Object
  //   ) {
  //     redirect({
  //       params: { page: 1 },
  //       query: store.state.products.filters[params.category],
  //     })

  // }
}
