export default function ({ path }, inject) {
  const srcImage = (path) => {
    return process.env.BUCKET_PATH + path + '.webp'
  }
  const lazySrcImage = (path) => {
    return process.env.BUCKET_PATH + path + '-lazy.webp'
  }

  inject('srcImage', srcImage)
  inject('lazySrcImage', lazySrcImage)
}
