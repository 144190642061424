export default function ({ $axios, store, error, app }, inject) {
  const novaPoshta = $axios.create({
    proxy: false,
    withCredentials: false,
    baseURL: process.env.NP_ENDPOINT,
  })
  // novaPoshta.onError((error) => {
  //   if (process.client) {
  //     app.$sentry.configureScope((scope) => {
  //       scope.setExtra('response', error.response)
  //       app.$sentry && app.$sentry.captureException(error)
  //     })
  //   }
  //   return Promise.reject(error)
  // })

  inject("np", novaPoshta)
}
