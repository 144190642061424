/* eslint-disable */

import Vue from "vue";
import {
  StoryblokVue,
  useStoryblokApi,
  useStoryblokBridge,
} from "@storyblok/nuxt-2";

import { apiPlugin } from "@storyblok/nuxt-2";

import fetchPonyfill from "fetch-ponyfill";
import { AbortController } from "node-abort-controller";

const { fetch, Request, Response, Headers } = fetchPonyfill();

if (global && !global.fetch) {
  global.fetch = fetch;
  global.Request = Request;
  global.Response = Response;
  global.Headers = Headers;
  global.AbortController = AbortController;
}

export default (ctx, inject) => {
  const { app, store } = ctx
  Vue.use(StoryblokVue, {
    accessToken: "mPebQkKD2c7TQeJAogRShAtt",
    bridge: true,
    apiOptions: {},

    use: [apiPlugin]
  });
  const api = useStoryblokApi()
  inject('storyapi', api)
  inject('storybridge', useStoryblokBridge)
};
