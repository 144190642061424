export default () => ({
  employees_quantity_array: ['1-10', '10-50', '50-100', '101-200'],
  type_point_of_sale_array: [
    'Нет',
    'Хозяйственный магазин',
    'Строительный магазин',
    'Строительный гипермаркет',
    'Специализированый кровельный магазин',
    'Интернет-магазин',
  ],
  work_types_array: [
    {
      text: 'Капітальне будівництво',
      value: 'Капітальне будівництво',
    },
    {
      text: 'Покрівля',
      value: 'Покрівля',
    },
    {
      text: 'Фасад',
      value: 'Фасад',
    },
    {
      text: 'Паркан',
      value: 'Паркан',
    },
    {
      text: 'Внутрішні роботи',
      value: 'Внутрішні роботи',
    },
  ],
  purpose_of_acquisition_array: [
    'Власні потреби',
    'Для подальшого перепродажу',
  ],
  type_account_builder_array: ['Фіз. особа', 'Юр. особа'],
  user: {
    id: '',
    phone: '',
    erp_id: null,
    phone_verified_at: null,
    two_factor_secret: null,
    two_factor_recovery_codes: null,
    active: 1,
    created_at: '',
    updated_at: '',
    profile: {
      id: '',
      user_id: '',
      name: '',
      email: '',
      surname: '',
      second_name: null,
      region: null,
      city: null,
      official_name: '',
      official_type: '',
      official_code: '',
      registration_details: {
        work_types: [],
        date_of_birth: '',
        physical_address: '',
        legal_address: '',
        working_territory: '',
        employees_quantity: '',
        purpose_of_acquisition: '',
        type_point_of_sale: '',
        type_of_builder: '',
      },
      created_at: '',
      updated_at: '',
    },
  },
  profile: {
    phone: '',
    name: '',
    surname: '',
    email: '',
    cities: [],
  },
  registration: {
    valid_first_step: false,
    valid_second_step: false,
    valid_third_step: false,
  },
})
