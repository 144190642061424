export default {
  TOGGLE_POPUPS_DISCOUNT(state) {
    state.discountWindowState = !state.discountWindowState
  },
  TURN_OFF_POPUPS_DISCOUNT(state) {
    state.discountWindowState = false
  },
  TOGGLE_POPUPS_DEALER(state) {
    state.dealerWindowState = !state.dealerWindowState
  },
  TURN_OFF_POPUPS_DEALER(state) {
    state.dealerWindowState = false
  },
  SET_VERSION_POPUPS_DEALER(state, payload) {
    state.versionPopupDealer = payload
  },
}
