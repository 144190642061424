// import VuexPersistence from 'vuex-persist'
// import localForage from 'localforage'
//
// const vuexPersist = new VuexPersistence({
//   strictMode: true, // This **MUST** be set to true
//   storage: localForage,
//   asyncStorage: true,
// })

// export const plugins = [vuexPersist.plugin]
// export const strict = true

export const state = () => ({
  error: {},
  barColor: "rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)",
  drawer: false,
  searchBar: {
    show: false,
  },
  storyBlockCv: "",
})

export const mutations = {
  SET_STORY_BLOCK_CV(state, payload) {
    state.storyBlockCv = payload
  },
  // RESTORE_MUTATION: vuexPersist.RESTORE_MUTATION,
  setError(state, error) {
    state.error = error
  },
  SHOWTOTOP(state, payload) {
    state.showtotop = payload
  },
  setDrawer(state, payload) {
    state.drawer = payload
  },
  TOOGLESEARCH(state) {
    state.searchBar.show = !state.searchBar.show
  },
}
export const actions = {
  async nuxtServerInit({ dispatch }) {
    await dispatch("content/get_categories")
    await dispatch("getStoryBlockCV")
  },
  getStoryBlockCV({ commit }) {
    this.$storyapi
      .get("cdn/spaces/me")
      .then((res) => {
        commit("SET_STORY_BLOCK_CV", res.data.space.version)
        return res.data
      })
      .catch((err) => {
        this.$nuxt.error({ statusCode: 404, err })
      })
  },
}
