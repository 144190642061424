export default {
  getDealerWindowState: (state) => {
    return state.dealerWindowState
  },
  getVersionPopupDealer: (state) => {
    return state.versionPopupDealer
  },
  getDiscountWindowState: (state) => {
    return state.discountWindowState
  },
}
