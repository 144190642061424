export default {
  getDateOfBirth(state) {
    if (
      state.user &&
      state.user.profile &&
      state.user.profile.registration_details &&
      state.user.profile.registration_details.date_of_birth
    ) {
      return state.user.profile.registration_details.date_of_birth
    } else {
      return ''
    }
  },
  getProfileCity(state) {
    if (state.user && state.user.profile.city) {
      return state.user.profile.city
    } else {
      return ''
    }
  },
  getOfficialName(state) {
    if (state.user && state.user.profile.official_name) {
      return state.user.profile.official_name
    } else {
      return ''
    }
  },
  getOfficialType(state) {
    if (state.user && state.user.profile.official_type) {
      return state.user.profile.official_type
    } else {
      return ''
    }
  },
  getOfficialCode(state) {
    if (state.user && state.user.profile.official_code) {
      return state.user.profile.official_code
    } else {
      return ''
    }
  },
}
