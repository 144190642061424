import {
  mdiAccount,
  mdiAccountCog,
  mdiAccountConvertOutline,
  mdiAccountEditOutline,
  mdiAccountHardHat,
  mdiAccountOutline,
  mdiAccountPlus,
  mdiAccountQuestion,
  mdiAccountTie,
  mdiAlert,
  mdiAlertOutline,
  mdiArrowCollapseVertical,
  mdiArrowDown,
  mdiArrowExpandHorizontal,
  mdiArrowExpandVertical,
  mdiArrowLeft,
  mdiArrowRight,
  mdiAtomVariant,
  mdiCached,
  mdiCalculator,
  mdiCalendar,
  mdiCancel,
  mdiCart,
  mdiCartArrowDown,
  mdiCartOutline,
  mdiCartPlus,
  mdiCartRemove,
  mdiChat,
  mdiCheck,
  mdiCheckboxBlankOutline,
  mdiCheckboxMarked,
  mdiCheckboxMarkedOutline,
  mdiCheckCircle,
  mdiChevronDoubleRight,
  mdiChevronDoubleUp,
  mdiChevronDown,
  mdiChevronLeft,
  mdiChevronRight,
  mdiChevronUp,
  mdiCircle,
  mdiClose,
  mdiCloseBox,
  mdiCloseCircle,
  mdiContentSaveOutline,
  mdiDelete,
  mdiDotsVertical,
  mdiEmailOutline,
  mdiExclamation,
  mdiEye,
  mdiEyeOff,
  mdiFacebook,
  mdiFeatureSearchOutline,
  mdiFilterMenu,
  mdiFilterRemove,
  mdiFormatColorFill,
  mdiFrequentlyAskedQuestions,
  mdiHandshake,
  mdiHeart,
  mdiHeartOutline,
  mdiHelp,
  mdiHome,
  mdiInformationVariant,
  mdiInstagram,
  mdiLinkedin,
  mdiLockOutline,
  mdiLockQuestion,
  mdiLogin,
  mdiLogout,
  mdiMagnify,
  mdiMagnifyClose,
  mdiMapMarkerRadius,
  mdiMenu,
  mdiMenuDown,
  mdiMinus,
  mdiMinusBox,
  mdiNewBox,
  mdiPageFirst,
  mdiPageLast,
  mdiPaperclip,
  mdiPencil,
  mdiPercentOutline,
  mdiPhone,
  mdiPhoneIncoming,
  mdiPlus,
  mdiQrcode,
  mdiRadioboxBlank,
  mdiRadioboxMarked,
  mdiScaleBalance,
  mdiScaleUnbalanced,
  mdiSend,
  mdiShieldRefreshOutline,
  mdiShoppingOutline,
  mdiSort,
  mdiSortAscending,
  mdiSortDescending,
  mdiStar,
  mdiStarHalfFull,
  mdiStarOutline,
  mdiTimelineCheckOutline,
  mdiUnfoldMoreHorizontal,
  mdiWave,
} from "@mdi/js"
import minifyTheme from "minify-css-string"
import colors from "vuetify/es5/util/colors"
import { en, ru, uk } from "vuetify/lib/locale"

export default {
  lang: {
    locales: { uk, ru, en },
    current: "uk",
  },
  theme: {
    options: {
      minifyTheme,
      variations: false,
    },
    dark: false,
    themes: {
      light: {
        primary: "#bd0000",
        secondary: "#0d1b2a",
        accent: "#0d1b2a",
        info: "#0000bd",
        truegrey: "#5e6264",
        back: "#fafafa",
        success: "#00bd00",
        error: "#b71c1c",
      },
      dark: {
        primary: colors.blue.darken2,
        accent: colors.grey.darken3,
        secondary: colors.amber.darken3,
        info: colors.teal.lighten1,
        warning: colors.amber.base,
        error: colors.deepOrange.accent4,
        success: colors.green.accent3,
      },
    },
  },
  icons: {
    values: {
      compare: mdiScaleBalance,
      compareAdded: mdiScaleUnbalanced,
      heart: mdiHeart,
      heartOutline: mdiHeartOutline,
      right: mdiArrowRight,
      menu: mdiMenu,
      check: mdiCheck,
      plus: mdiPlus,
      minus: mdiMinus,
      cartRemove: mdiCartRemove,
      arrowDown: mdiArrowDown,
      expandHorizontal: mdiArrowExpandHorizontal,
      expandVertical: mdiArrowExpandVertical,
      arrowLeft: mdiArrowLeft,
      checkboxOff: mdiCheckboxBlankOutline,
      checkboxOn: mdiCheckboxMarked,
      phoneIcon: mdiPhone,
      marker: mdiMapMarkerRadius,
      subgroup: mdiMenuDown,
      breadcrumbs: mdiChevronDoubleRight,
      info: mdiInformationVariant,
      sort: mdiSort,
      sort_asc: mdiSortAscending,
      sort_desc: mdiSortDescending,
      sort_new: mdiNewBox,
      dropdown: mdiMenuDown,
      filter_on: mdiFilterMenu,
      filter_off: mdiFilterRemove,
      complete: mdiCheck,
      cancel: mdiCloseCircle,
      close: mdiClose,
      delete: mdiCloseCircle, // delete (e.g. v-chip close)
      clear: mdiClose,
      success: mdiCheckCircle,
      warning: mdiExclamation,
      error: mdiAlert,
      prev: mdiChevronLeft,
      next: mdiChevronRight,
      checkboxIndeterminate: mdiMinusBox,
      delimiter: mdiCircle, // for carousel
      expand: mdiChevronDown,
      radioOn: mdiRadioboxMarked,
      radioOff: mdiRadioboxBlank,
      edit: mdiPencil,
      ratingEmpty: mdiStarOutline,
      ratingFull: mdiStar,
      ratingHalf: mdiStarHalfFull,
      loading: mdiCached,
      first: mdiPageFirst,
      last: mdiPageLast,
      unfold: mdiUnfoldMoreHorizontal,
      file: mdiPaperclip,
      waves: mdiWave,
      colorfill: mdiFormatColorFill,
      variant: mdiAtomVariant,
      colapver: mdiArrowCollapseVertical,
      cartPlus: mdiCartPlus,
      lookup: mdiFeatureSearchOutline,
      logout: mdiLogout,
      shoppingOutline: mdiShoppingOutline,
      accountOutline: mdiAccountOutline,
      sendFormIcon: mdiEmailOutline,
      sendIcon: mdiSend,
      emptyCartIcon: mdiCartOutline,
      magnify: mdiMagnify,
      chevronUp: mdiChevronUp,
      accountCog: mdiAccountCog,
      cartIcon: mdiCart,
      facebook: mdiFacebook,
      linkedin: mdiLinkedin,
      instagram: mdiInstagram,
      magnifyClose: mdiMagnifyClose,
      handsIcon: mdiHandshake,
      call: mdiPhoneIncoming,
      help: mdiHelp,
      cartIconTwo: mdiCartArrowDown,
      onIcon: mdiCheckboxMarkedOutline,
      closeBox: mdiCloseBox,
      refresh: mdiShieldRefreshOutline,
      register_icon: mdiAccountPlus,
      login_icon: mdiLogin,
      forgot_icon: mdiLockQuestion,
      lock: mdiLockOutline,
      eye: mdiEye,
      eyeOff: mdiEyeOff,
      haveAccount: mdiAccountQuestion,
      account: mdiAccount,
      accountHardHat: mdiAccountHardHat,
      accountTie: mdiAccountTie,
      dots: mdiDotsVertical,
      deleteIcon: mdiDelete,
      cancelIcon: mdiCancel,
      saveOutline: mdiContentSaveOutline,
      mdiPercentOutline,
      qrIcon: mdiQrcode,
      timelineIcon: mdiTimelineCheckOutline,
      calendar: mdiCalendar,
      accountEditOutline: mdiAccountEditOutline,
      update: mdiAccountConvertOutline,
      home: mdiHome,
      questions: mdiFrequentlyAskedQuestions,
      chatIcon: mdiChat,
      go_up: mdiChevronDoubleUp,
      alert: mdiAlertOutline,
    },
  },
}
