
export default {
  name: 'SbHeading',
  props: {
    attrs: {
      type: Object,
      required: true,
    },
  },
  computed: {
    tag() {
      if (this.attrs && this.attrs?.level) {
        return 'h' + this.attrs.level
      }
    },
  },
}
