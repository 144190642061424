
export default {
  name: 'SbImage',
  props: {
    attrs: {
      type: Object,
      required: true,
    },
  },
}
