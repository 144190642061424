export default {
  ADD_NEW_CATEGORY(state, product) {
    const originCategory = product?.category?.alias // Категория на англ
    const transCategory = product?.category?.trans_title // Категория на рус
    const image =
      product?.category?.attachments?.[0] || product?.options?.attachments?.[0]
    product.options.attributes =
      product.options?.characteristic?.attributes ?? product.options.attributes
    state.compareProducts.push({
      alias: originCategory,
      title: transCategory,
      imageCategory: image,
      items: [product?.options],
    })
  },
  ADD_PRODUCT(state, product) {
    const originCategory = product?.category?.alias // Категория на англ
    const findAlias = (el) => el?.alias === originCategory // Ищем такую же категорию по названию
    const categoryIndex = state.compareProducts?.findIndex(findAlias) //
    product.options.attributes =
      product.options?.characteristic?.attributes ?? product.options.attributes
    state.compareProducts[categoryIndex].items.push(product.options)
  },
  REMOVING_CATEGORY(state, product) {
    const originCategory = product?.category?.alias || product
    const findAlias = (el) => el?.alias === originCategory
    const categoryIndex = state.compareProducts?.findIndex(findAlias)

    // Удаляем такой объект по индексу
    state.compareProducts.splice(categoryIndex, 1)
  },
  REMOVE_PRODUCT(state, product) {
    const originCategory = product?.category?.alias || product.alias
    const findAlias = (el) => el?.alias === originCategory
    const categoryIndex = state.compareProducts?.findIndex(findAlias)

    const findItem = (el) => el?.alias === product?.options?.alias

    // Получаем массив товаров
    const maxItems = state.compareProducts[categoryIndex].items

    // Удаляем товар на текущей позиции
    maxItems.splice(
      state.compareProducts[categoryIndex].items.findIndex(findItem),
      1
    )
  },
  TOGGLE_DIALOG_COMPARE(state) {
    state.dialogCompare = !state.dialogCompare
  },
}
