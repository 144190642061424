import Vue from 'vue'

Vue.directive('gtm-push', {
  bind: (el, binding, VNode) => {
    // assign event to the element
    el.clickOutsideEvent = function (event) {
      if (el.contains(event.target)) {
        VNode.context.$gtm.push({
          event: binding.value.event, // => "name event "
          info: binding.value.text.trans_name, // => "info"
          isMobile: binding.value.isMobile, // => "isMobile"
        })
      }
    }
    // register click and touch events
    if (binding.value.push) {
      document.body.addEventListener('click', el.clickOutsideEvent)
    }
  },
  unbind(el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  },
  stopProp(event) {
    event.stopPropagation()
  },
})
