
import lottie from 'vue-lottie/src/lottie.vue'
import * as animationData404 from '@/assets/animations/404.json'
import * as animationData424 from '@/assets/animations/424.json'
import * as animationData500 from '@/assets/animations/500.json'

export default {
  components: {
    lottie,
  },
  layout: 'empty',
  props: {
    error: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      pageNotFound: '404 Not Found',
      otherError: 'An error occurred',
      lottieOptions404: {animationData: animationData404.default},
      lottieOptions424: {animationData: animationData424.default},
      lottieOptions500: {animationData: animationData500.default},
    }
  },
  head() {
    const title =
      this.error.statusCode === 404 ? this.pageNotFound : this.otherError
    return {
      title,
    }
  },
}
