export default {
  setUserPhone(state, payload) {
    state.user.profile.phone = payload
  },
  SET_USER(state, payload) {
    state.user = payload
  },
  SET_VALID_FIRST_STEP(state, payload) {
    state.registration.valid_first_step = payload
  },
  SET_VALID_SECOND_STEP(state, payload) {
    state.registration.valid_second_step = payload
  },
  SET_VALID_THIRD_STEP(state, payload) {
    state.registration.valid_third_step = payload
  },
  SET_PROFILE_NAME(state, payload) {
    if (payload === null) {
      state.user.profile.name = ''
    } else {
      state.user.profile.name = payload
    }
  },
  SET_PROFILE_EMAIL(state, payload) {
    if (payload === null) {
      state.user.profile.email = ''
    } else {
      state.user.profile.email = payload
    }
  },
  SET_PROFILE_SURNAME(state, payload) {
    if (payload === null) {
      state.user.profile.surname = ''
    } else {
      state.user.profile.surname = payload
    }
  },
  SET_PROFILE_CITY(state, payload) {
    if (payload === null) {
      state.user.profile.city = ''
    } else {
      state.user.profile.city = payload
    }
  },
  SET_PROFILE_DATE_OF_BIRTH(state, payload) {
    if (payload === null) {
      state.user.profile.registration_details.date_of_birth = '1'
    } else {
      state.user.profile.registration_details.date_of_birth = payload
    }
  },
  SET_PROFILE_OFFICIAL_NAME(state, payload) {
    if (payload === null) {
      state.user.profile.official_name = ''
    } else {
      state.user.profile.official_name = payload
    }
  },
  SET_PROFILE_OFFICIAL_TYPE(state, payload) {
    if (payload === null) {
      state.user.profile.official_type = ''
    } else {
      state.user.profile.official_type = payload
    }
  },
  SET_PROFILE_OFFICIAL_CODE(state, payload) {
    if (payload === null) {
      state.user.profile.official_code = ''
    } else {
      state.user.profile.official_code = payload
    }
  },
  SET_PROFILE_PHYSICAL_ADDRESS(state, payload) {
    if (payload === null) {
      state.user.profile.registration_details.physical_address = ''
    } else {
      state.user.profile.registration_details.physical_address = payload
    }
  },
  SET_PROFILE_LEGAL_ADDRESS(state, payload) {
    if (payload === null) {
      state.user.profile.registration_details.legal_address = ''
    } else {
      state.user.profile.registration_details.legal_address = payload
    }
  },
  SET_PROFILE_WORKING_TERRITORY(state, payload) {
    if (payload === null) {
      state.user.profile.registration_details.working_territory = ''
    } else {
      state.user.profile.registration_details.working_territory = payload
    }
  },
  SET_PROFILE_EMPLOYEES_QUANTITY(state, payload) {
    if (payload === null) {
      state.user.profile.registration_details.employees_quantity = ''
    } else {
      state.user.profile.registration_details.employees_quantity = payload
    }
  },
  SET_PROFILE_PURPOSE_OF_ACQUISITION(state, payload) {
    if (payload === null) {
      state.user.profile.registration_details.purpose_of_acquisition = ''
    } else {
      state.user.profile.registration_details.purpose_of_acquisition = payload
    }
  },
  SET_PROFILE_TYPE_POINT_OF_SALE(state, payload) {
    if (payload === null) {
      state.user.profile.registration_details.type_point_of_sale = ''
    } else {
      state.user.profile.registration_details.type_point_of_sale = payload
    }
  },
  SET_PROFILE_WORK_TYPES(state, payload) {
    if (payload === null) {
      state.user.profile.registration_details.work_types = ''
    } else {
      state.user.profile.registration_details.work_types = payload
    }
  },
  SET_PROFILE_TYPE_OF_BUILDER(state, payload) {
    if (payload === null) {
      state.user.profile.registration_details.type_of_builder = ''
    } else {
      state.user.profile.registration_details.type_of_builder = payload
    }
  },
}
