export default {
  changeLength({ commit, state }, payload) {
    commit("changeSelectedLength", payload)
  },
  clearCart({ commit }) {
    commit("clearProducts")
    commit("clearOrderID")
  },
  changeQuantity({ commit, state }, payload) {
    if (payload.item?.uses_options === 1) {
      commit("changeQuantityOption", payload)
    } else {
      commit("changeQuantityProduct", payload)
    }
  },
  addOptionToCart({ commit, state }, payload) {
    const copy = this.$cloneDeep(payload.option)
    // const savedCartItems = [...state.products]
    if (
      state.products.some(
        (e) => e.id === copy.id && e.product_length === copy.product_length
      )
    ) {
      // console.log('Товар идентичен и добавляем количество')
      // Если совпала длина, Гуид и Продакт_Айди, то такой товар уже есть доавбляем количество
      commit("ADD_EXISTING_PRODUCT", copy)
    } else {
      // console.log('Товар ДРУГОЙ просто добавляем в корзину')
      // Если нет то создаём.
      commit("ADD_NEW_PRODUCT", copy)
    }
  },
}
