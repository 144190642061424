export default {
  INCREASE_ARTICLES_PAGE(state) {
    state.currentPageArticlesList++
  },
  DECREASE_ARTICLES_PAGE(state) {
    state.currentPageArticlesList--
  },
  SET_ARTICLES_PAGE(state, page) {
    state.currentPageArticlesList = page
  },
}
