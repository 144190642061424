export default {
  listCategory: (state) => {
    return state.compareProducts
  },
  dialogCompare: (state) => {
    return state.dialogCompare
  },
  getCompareProducts: (state) => (alias) => {
    return state.compareProducts.find((category) => category?.alias === alias)
  },
  getCompareAttributes: (state, getters) => (alias) => {
    const products = getters.getCompareProducts(alias)
    let attributes = []
    let uniqListAttributes = []

    if (!products) return 0

    products?.items.forEach((item) => {
      attributes = attributes.concat(item.attributes)
    })

    const unique = [
      ...new Map(attributes.map((item) => [item.type, item])).values(),
    ]

    uniqListAttributes = unique.map((index) => {
      return {
        type: index?.type,
        title: index?.trans_title,
      }
    })
    return uniqListAttributes
  },
}
