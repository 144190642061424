const getDefaultState = () => ({
  categories: [],
  client_version: "1",
  banners: [],
  articles: [],
  baseCategories: [
    {
      title: "main_page",
      to: "/",
      children: [],
    },
    {
      title: "shop",
      children: [],
    },
    {
      title: "about_company",
      products_count: 3,
      static: true,
      children: [
        {
          title: "about_us",
          to: "/pages/about_us",
          children: [],
          products_count: 1,
          static: true,
        },
        {
          title: "vacancies",
          to: "/pages/vacancies",
          children: [],
          products_count: 1,
          static: true,
        },
        {
          title: "director",
          to: "/pages/director",
          children: [],
          products_count: 1,
          static: true,
        },
        {
          title: "dealer",
          to: "/pages/dealer_invitation",
          children: [],
          static: true,
        },
        {
          title: "blog",
          to: "/articles/1",
          children: [],
          static: true,
        },
        // {
        //   title: 'articles',
        //   to: '/articles',
        //   children: [],
        //   products_count: 1,
        //   static: true,
        // },
        // {
        //   title: 'news',
        //   to: '/news',
        //   children: [],
        //   products_count: 1,
        //   static: true,
        // },
      ],
    },
    {
      title: "how_to_buy",
      to: "/information",
      products_count: 3,
      children: [
        {
          title: "delivery",
          to: "/delivery",
          children: [],
          products_count: 1,
          static: true,
        },
        {
          title: "payment",
          to: "/payment",
          children: [],
          products_count: 1,
          static: true,
        },
        {
          title: "guarantee",
          to: "/guarantee",
          children: [],
          products_count: 1,
          static: true,
        },
      ],
      static: true,
    },
    {
      title: "photo_gallery",
      to: "/gallery",
      children: [],
      static: true,
    },
    {
      title: "contacts",
      to: "/pages/contacts",
      children: [],
      static: true,
    },
  ],
})
export const state = () => getDefaultState()
export const mutations = {
  resetState(state) {
    state = this.$cloneDeep(getDefaultState())
  },
  SET_CLIENT_VERSION(state, payload) {
    state.client_version = payload
  },
  add(state, text) {
    state.list.push({
      text,
      done: false,
    })
  },
  categoriesGet(state, categories) {
    state.baseCategories[1].children = categories
    state.categories = categories
  },
  bannersGet(state, banners) {
    Object.assign(state.banners, banners)
  },
  articlesGet(state, articles) {
    Object.assign(state.articles, articles)
  },
}
export const getters = {
  getCategories(state) {
    return state.categories
  },
  getClientVersion(state) {
    return state.client_version
  },
}
export const actions = {
  resetContentState({ commit }) {
    commit("resetState")
  },
  get_categories({ commit, $sentry }) {
    const locale = this.$i18n.locale
    return this.$axios
      .get("/api/v2/categories?lang=" + locale)
      .then((response) => {
        commit("categoriesGet", response.data.data)
      })
      .catch((error) => {
        // $sentry.captureException(error)
        commit("setError", error, { root: true })
      })
  },
}
