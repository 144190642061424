export default (context) => {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations().then(function (registrations) {
      for (const registration of registrations) {
        registration.update()
      }
    })
  }
  // const workbox = await window.$workbox
  //
  // if (!workbox) {
  //   console.debug("Workbox couldn't be loaded.")
  //   return
  // }
  //
  // workbox.addEventListener('installed', (event) => {
  //   if (!event.isUpdate) {
  //     console.debug('The PWA is on the latest version.')
  //     return
  //   }
  //
  //   console.debug('There is an update for the PWA, reloading...')
  //
  //   const cookies = document.cookie.split(';')
  //
  //   for (let i = 0; i < cookies.length; i++) {
  //     const cookie = cookies[i]
  //     const eqPos = cookie.indexOf('=')
  //     const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie
  //     document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT'
  //   }
  //
  //   caches.keys().then((cacheNames) => {
  //     cacheNames.forEach((cacheName) => {
  //       caches.delete(cacheName)
  //     })
  //   })
  //   window.localStorage.clear()
  //   window.sessionStorage.clear()
  //   window.location.reload()
  // })
}
