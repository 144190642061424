import VuexPersistence from 'vuex-persist'
// import localforage from 'localforage'

export default ({ store }) => {
  new VuexPersistence({
    key: 'vuex',
    // storage: localforage,
    // asyncStorage: true, // THIS SEEMS TO BE IMPORTANT FOR localforage, with window.localstorage it also worked without it
  }).plugin(store)
}
